import React, { useState, useEffect } from 'react';
import { Table, Button, DatePicker, Select, Form, Checkbox } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';

const { RangePicker } = DatePicker;
const { Option } = Select;

const BotTransactions = () => {
    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [dateRange, setDateRange] = useState([dayjs().startOf('day'), dayjs().endOf('day')]);
    const [selectedColumns, setSelectedColumns] = useState(['bot_id', 'bot_name', 'transaction_type', 'quantity', 'rate', 'keys', 'created_at']);
    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        form.setFieldsValue({
            dateRange: [dayjs().startOf('day'), dayjs().endOf('day')]
        });
        form.submit();
    }, [form]);

    const fetchTransactions = async (values) => {
        setLoading(true);
        try {
            const params = {
                bot_id: values.botIDs && values.botIDs.length > 0 ? values.botIDs : undefined,
                bot_name: values.botNames && values.botNames.length > 0 ? values.botNames : undefined,
                start_date: values.dateRange ? dayjs(values.dateRange[0]).toISOString() : undefined,
                end_date: values.dateRange ? dayjs(values.dateRange[1]).toISOString() : undefined,
                limit: 1000,
            };

            // Удаляем неопределенные параметры
            Object.keys(params).forEach(key => {
                if (params[key] === undefined) {
                    delete params[key];
                }
            });

            const response = await axios.get('https://api.steam-supply.bigdzik.com/api/transactions', { params });
            // const response = await axios.get('http://localhost:8091/api/transactions', { params });
            setData(response.data);
            setFilteredData(response.data); // Изначально отображаем все данные
            setDateRange(values.dateRange);
        } catch (error) {
            console.error("Error fetching bot transactions:", error);
        }
        setLoading(false);
    };

    const handleTransactionTypeChange = (value) => {
        if (value) {
            setFilteredData(data.filter(item => item.transaction_type === value));
        } else {
            setFilteredData(data); // Если значение не выбрано, показываем все данные
        }
    };

    const columns = [
        { title: 'Bot ID', dataIndex: 'bot_id', key: 'bot_id', sorter: (a, b) => a.bot_id.localeCompare(b.bot_id) },
        { title: 'Bot Name', dataIndex: 'bot_name', key: 'bot_name', sorter: (a, b) => a.bot_name.localeCompare(b.bot_name) },
        {
            title: 'Transaction Type', dataIndex: 'transaction_type', key: 'transaction_type', sorter: (a, b) => a.transaction_type.localeCompare(b.transaction_type),
            render: (text) => {
                if (text === 'buy') {
                    return <span><ArrowUpOutlined style={{ color: 'green' }} /> Buy</span>;
                } else if (text === 'sell') {
                    return <span><ArrowDownOutlined style={{ color: 'red' }} /> Sell</span>;
                } else {
                    return <span>{text}</span>;
                }
            }
        },
        { title: 'Sets', dataIndex: 'quantity', key: 'quantity', sorter: (a, b) => a.quantity - b.quantity },
        { title: 'Rate', dataIndex: 'rate', key: 'rate', sorter: (a, b) => a.rate - b.rate },
        {
            title: 'Keys',
            dataIndex: '',
            key: 'keys',
            render: (text, record) => {
                const { quantity, rate } = record;
                if (rate === 0) {
                    return 'N/A';
                }
                const keys = quantity / rate;
                const roundedKeys = Math.ceil(keys);
                const originalKeys = keys % 1 === 0 ? keys.toFixed(0) : keys.toFixed(1).replace(/\.0$/, '');
                return (
                    <span>
                {roundedKeys} {roundedKeys !== parseFloat(originalKeys) && <span style={{ fontSize: '0.8em', color: 'grey' }}>({originalKeys})</span>}
            </span>
                );
            },
            sorter: (a, b) => (a.quantity / a.rate) - (b.quantity / b.rate),
        },
        {
            title: 'Created At',
            dataIndex: 'created_at',
            key: 'created_at',
            sorter: (a, b) => new Date(b.created_at) - new Date(a.created_at),
            defaultSortOrder: 'ascend',
            render: (text) => {
                return dayjs(text).format('YYYY-MM-DD HH:mm:ss');
            },
        },
    ];

    const onFinish = (values) => {
        fetchTransactions(values);
    };

    const handleColumnChange = (checkedValues) => {
        setSelectedColumns(checkedValues);
    };

    const filteredColumns = columns.filter(col => selectedColumns.includes(col.key));

    return (
        <div>
            <Form form={form} layout="inline" onFinish={onFinish}>
                <Form.Item name="botIDs" label="Bot ID">
                    <Select mode="tags" placeholder="Enter Bot IDs" style={{ minWidth: 200 }} dropdownRender={menu => menu} />
                </Form.Item>
                <Form.Item name="botNames" label="Bot Name">
                    <Select mode="tags" placeholder="Enter Bot Names" style={{ minWidth: 200 }} dropdownRender={menu => menu} />
                </Form.Item>
                <Form.Item name="transactionType" label="Transaction Type">
                    <Select placeholder="Select Transaction Type" style={{ minWidth: 200 }} onChange={handleTransactionTypeChange}>
                        <Option value="buy">Buy</Option>
                        <Option value="sell">Sell</Option>
                        <Option value="">All</Option>
                    </Select>
                </Form.Item>
                <Form.Item name="dateRange" label="Date Range">
                    <RangePicker showTime={{ format: 'HH:mm' }} format="YYYY-MM-DD HH:mm:ss" />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loading}>Search</Button>
                </Form.Item>
            </Form>
            <div>
                <h2>Bot Transactions from {dateRange[0].format('YYYY-MM-DD HH:mm')} to {dateRange[1].format('YYYY-MM-DD HH:mm')}</h2>
                <Form.Item label="Select Columns">
                    <Checkbox.Group
                        options={columns.map(col => ({ label: col.title, value: col.key }))}
                        value={selectedColumns}
                        onChange={handleColumnChange}
                    />
                </Form.Item>
                <Table
                    columns={filteredColumns}
                    dataSource={filteredData}
                    rowKey="id"
                    loading={loading}
                    style={{ marginTop: 16 }}
                    pagination={{ pageSize: 1000 }}
                    defaultSortOrder={{ field: 'created_at', order: 'descend' }}
                />
            </div>
        </div>
    );
};

export default BotTransactions;
