import React, { useState, useEffect } from 'react';
import { Table, Input, Button, DatePicker, Select, Form, Checkbox } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';

const { RangePicker } = DatePicker;
const { Option } = Select;

const BotChanges = () => {
    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [summaryData, setSummaryData] = useState([]);
    const [showSummary, setShowSummary] = useState(true);
    const [dateRange, setDateRange] = useState([dayjs().startOf('day'), dayjs().endOf('day')]);
    const [selectedColumns, setSelectedColumns] = useState(['bot_id', 'bot_name', 'field_name', 'change', 'old_value', 'new_value', 'created_at']);

    useEffect(() => {
        form.setFieldsValue({
            dateRange: [dayjs().startOf('day'), dayjs().endOf('day')]
        });
        form.submit();
    }, [form]);

    const fetchChanges = async (values) => {
        setLoading(true);
        try {
            const params = {
                bot_id: values.botIDs && values.botIDs.length > 0 ? values.botIDs : undefined,
                bot_name: values.botNames && values.botNames.length > 0 ? values.botNames : undefined,
                field_name: values.fieldNames && values.fieldNames.length > 0 ? values.fieldNames : undefined,
                start_date: values.dateRange ? dayjs(values.dateRange[0]).toISOString() : undefined,
                end_date: values.dateRange ? dayjs(values.dateRange[1]).toISOString() : undefined,
                limit: 1000,
            };

            // Удаляем неопределенные параметры
            Object.keys(params).forEach(key => {
                if (params[key] === undefined) {
                    delete params[key];
                }
            });

            const response = await axios.get('https://api.steam-supply.bigdzik.com/api/changes', { params });
            setData(response.data);
            calculateSummary(response.data);
            setDateRange(values.dateRange);
        } catch (error) {
            console.error("Error fetching bot changes:", error);
        }
        setLoading(false);
    };

    const calculateSummary = (data) => {
        const summary = {};
        data.forEach(change => {
            if (change.field_name === 'stock') {
                const botID = change.bot_id;
                const botName = change.bot_name || "Unknown"; // If bot_name is missing, set it to "Unknown"
                const oldValue = parseInt(change.old_value, 10);
                const newValue = parseInt(change.new_value, 10);
                const changeAmount = newValue - oldValue;

                if (!summary[botID]) {
                    summary[botID] = { bot_id: botID, bot_name: botName, bought: 0, sold: 0, buy_transactions: 0, sell_transactions: 0 };
                } else if (!summary[botID].bot_name) {
                    summary[botID].bot_name = botName; // Update bot_name if it's missing
                }

                if (changeAmount > 0) {
                    summary[botID].bought += changeAmount;
                    summary[botID].buy_transactions += 1;
                } else if (changeAmount < 0) {
                    summary[botID].sold += Math.abs(changeAmount);
                    summary[botID].sell_transactions += 1;
                }
            }
        });

        setSummaryData(Object.values(summary));
    };

    const columns = [
        { title: 'Bot ID', dataIndex: 'bot_id', key: 'bot_id', sorter: (a, b) => a.bot_id.localeCompare(b.bot_id) },
        { title: 'Bot Name', dataIndex: 'bot_name', key: 'bot_name', sorter: (a, b) => a.bot_name.localeCompare(b.bot_name) },
        { title: 'Field Name', dataIndex: 'field_name', key: 'field_name', sorter: (a, b) => a.field_name.localeCompare(b.field_name),
            render: (text) => fieldNameMapping[text] || text
        },
        {
            title: 'Change',
            dataIndex: '',
            key: 'change',
            render: (text, record) => {
                const oldValue = parseFloat(record.old_value);
                const newValue = parseFloat(record.new_value);
                const changeAmount = newValue - oldValue;
                const formattedChange = changeAmount % 1 === 0 ? changeAmount.toFixed(0) : changeAmount.toFixed(2).replace(/\.?0+$/, '');

                if (changeAmount > 0) {
                    return <span><ArrowUpOutlined style={{ color: 'green' }} /> {formattedChange}</span>;
                } else if (changeAmount < 0) {
                    return <span><ArrowDownOutlined style={{ color: 'red' }} /> {Math.abs(formattedChange)}</span>;
                } else {
                    return <span>0</span>;
                }
            },
            sorter: (a, b) => {
                const changeA = parseFloat(a.new_value) - parseFloat(a.old_value);
                const changeB = parseFloat(b.new_value) - parseFloat(b.old_value);
                return changeA - changeB;
            }
        },
        { title: 'Old Value', dataIndex: 'old_value', key: 'old_value', sorter: (a, b) => a.old_value - b.old_value },
        { title: 'New Value', dataIndex: 'new_value', key: 'new_value', sorter: (a, b) => a.new_value - b.new_value },
        {
            title: 'Created At',
            dataIndex: 'created_at',
            key: 'created_at',
            sorter: (a, b) => new Date(b.created_at) - new Date(a.created_at),
            render: (text) => {
                return dayjs(text).format('YYYY-MM-DD HH:mm:ss');
            },
        },
    ];

    const fieldNameMapping = {
        'stock': 'Stock',
        'games': 'Games',
        'csgo_sell_rate': 'CS:GO Sell Rate',
        'csgo_buy_rate': 'CS:GO Buy Rate',
        'tf_sell_rate': 'TF2 Sell Rate',
        'tf_buy_rate': 'TF2 Buy Rate',
        'hydra_sell_rate': 'Hydra Sell Rate',
        'hydra_buy_rate': 'Hydra Buy Rate',
        'gem_sell_rate': 'Gems Sell Rate',
        'gem_buy_rate': 'Gems Buy Rate'
    };

    const summaryColumns = [
        { title: 'Bot ID', dataIndex: 'bot_id', key: 'bot_id', sorter: (a, b) => a.bot_id.localeCompare(b.bot_id) },
        { title: 'Bot Name', dataIndex: 'bot_name', key: 'bot_name', sorter: (a, b) => a.bot_name.localeCompare(b.bot_name) },
        { title: 'Bought Sets', dataIndex: 'bought', key: 'bought', sorter: (a, b) => b.bought - a.bought },
        { title: 'Sold Sets', dataIndex: 'sold', key: 'sold', sorter: (a, b) => b.sold - a.sold, defaultSortOrder: 'ascend' },
        { title: 'Buy Transactions', dataIndex: 'buy_transactions', key: 'buy_transactions', sorter: (a, b) => b.buy_transactions - a.buy_transactions },
        { title: 'Sell Transactions', dataIndex: 'sell_transactions', key: 'sell_transactions', sorter: (a, b) => b.sell_transactions - a.sell_transactions },
    ];

    const onFinish = (values) => {
        fetchChanges(values);
    };

    const onShowSummaryChange = (e) => {
        setShowSummary(e.target.checked);
    };

    const handleColumnChange = (checkedValues) => {
        setSelectedColumns(checkedValues);
    };

    const filteredColumns = columns.filter(col => selectedColumns.includes(col.key));

    return (
        <div>
            <Form form={form} layout="inline" onFinish={onFinish}>
                <Form.Item name="botIDs" label="Bot ID">
                    <Select mode="tags" placeholder="Enter Bot IDs" style={{ minWidth: 200 }} dropdownRender={menu => menu} />
                </Form.Item>
                <Form.Item name="botNames" label="Bot Name">
                    <Select mode="tags" placeholder="Enter Bot Names" style={{ minWidth: 200 }} dropdownRender={menu => menu} />
                </Form.Item>
                <Form.Item name="fieldNames" label="Field Names">
                    <Select mode="multiple" placeholder="Select Field Names" style={{ minWidth: 200 }}>
                        <Option value="stock">Stock</Option>
                        <Option value="games">Games</Option>
                        <Option value="csgo_sell_rate">CSGO Sell Rate</Option>
                        <Option value="csgo_buy_rate">CSGO Buy Rate</Option>
                        <Option value="tf_sell_rate">TF Sell Rate</Option>
                        <Option value="tf_buy_rate">TF Buy Rate</Option>
                        <Option value="hydra_sell_rate">Hydra Sell Rate</Option>
                        <Option value="hydra_buy_rate">Hydra Buy Rate</Option>
                        <Option value="gem_sell_rate">Gem Sell Rate</Option>
                        <Option value="gem_buy_rate">Gem Buy Rate</Option>
                    </Select>
                </Form.Item>
                <Form.Item name="dateRange" label="Date Range">
                    <RangePicker showTime={{ format: 'HH:mm' }} format="YYYY-MM-DD HH:mm:ss" />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loading}>Search</Button>
                </Form.Item>
                <Form.Item>
                    <Checkbox checked={showSummary} onChange={onShowSummaryChange}>Show Statistics for bots (field name `Stock` should be enabled)</Checkbox>
                </Form.Item>
            </Form>
            {showSummary && (
                <div>
                    <h2>Statistics from {dateRange[0].format('YYYY-MM-DD HH:mm')} to {dateRange[1].format('YYYY-MM-DD HH:mm')}</h2>
                    <Table
                        columns={summaryColumns}
                        dataSource={summaryData}
                        rowKey="bot_id"
                        loading={loading}
                        style={{ marginTop: 16 }}
                        pagination={{ pageSize: 1000 }}
                        defaultSortOrder={{ field: 'sold', order: 'ascend' }}
                    />
                </div>
            )}
            <div>
                <h2>Bot logs
                    from {dateRange[0].format('YYYY-MM-DD HH:mm')} to {dateRange[1].format('YYYY-MM-DD HH:mm')}</h2>
                <Form.Item label="Select Columns">
                    <Checkbox.Group
                        options={columns.map(col => ({ label: col.title, value: col.key }))}
                        value={selectedColumns}
                        onChange={handleColumnChange}
                    />
                </Form.Item>
                <Table
                    columns={filteredColumns}
                    dataSource={data}
                    rowKey="id"
                    loading={loading}
                    style={{ marginTop: 16 }}
                    pagination={{ pageSize: 1000 }}
                    defaultSortOrder={{ field: 'created_at', order: 'descend' }}
                />
            </div>
        </div>
    );
};

export default BotChanges;
