import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Menu } from 'antd';

const NavigationMenu = () => {
    const location = useLocation();
    const selectedKey = location.pathname === '/transactions' ? '2' : '1';

    return (
        <Menu theme="dark" mode="horizontal" selectedKeys={[selectedKey]}>
            <Menu.Item key="1">
                <Link to="/">Bot Changes</Link>
            </Menu.Item>
            <Menu.Item key="2">
                <Link to="/transactions">Bot Transactions</Link>
            </Menu.Item>
        </Menu>
    );
};

export default NavigationMenu;
