import React, { useState } from 'react';
import { Layout } from 'antd';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import NavigationMenu from './components/NavigationMenu';
import BotChanges from './components/BotChanges';
import BotTransactions from './components/BotTransactions';

const { Header, Content, Footer } = Layout;

const App = () => {
    return (
        <Router>
            <Layout className="layout">
                <Header>
                    <NavigationMenu />
                </Header>
                <Content style={{ padding: '0 50px', marginTop: '16px' }}>
                    <div className="site-layout-content">
                        <Routes>
                            <Route exact path="/" element={<BotChanges />} />
                            <Route path="/transactions" element={<BotTransactions />} />
                        </Routes>
                    </div>
                </Content>
                <Footer style={{ textAlign: 'center' }}>Bot Changes ©2023</Footer>
            </Layout>
        </Router>
    );
};

export default App;
